import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {getRecommendedUserProfile, PartnerUser} from "../../utils/api/recommend";
import {Card, Image, Space, Table, Tag, Typography} from 'antd';
import styled from "@emotion/styled";
import MatchLayout from "./components/RecommendationLayout";
import type {ColumnsType} from "antd/es/table";
import {calculateColumnsTotalWidth, formatKoreanMoney, getTagColor, TitleWrapper} from "./RecommendationHome";
import RecommendedUserProfileCard from "./components/RecommendedUserProfileCard";

const RecommendationDetail = () => {
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();

    const {data: recommendationUserQueryResult} = useQuery({
        queryKey: ["matching-users", params.id],
        queryFn: () => getRecommendedUserProfile({
            name: "",
            userId: params.id
        }),
        select: (data) => ({
            contents: data.data
        })
    });

    const content = recommendationUserQueryResult?.contents;
    const receivedRecommendations = content?.receivedRecommendations;
    const receivedUpperLevelRecommendations = receivedRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "미끼 추천"
    );
    const receivedSameLevelPrimaryRecommendations = receivedRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "동급 괜찮은 추천"
    );
    const receivedSameLevelSecondaryRecommendations = receivedRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "동급 아쉬운 추천"
    );

    const sentRecommendations = content?.sentRecommendations;
    const sentUpperLevelRecommendations = sentRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "미끼 추천"
    );
    const sentSameLevelPrimaryRecommendations = sentRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "동급 괜찮은 추천"
    );
    const sentSameLevelSecondaryRecommendations = sentRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "동급 아쉬운 추천"
    );

    const columns: ColumnsType<PartnerUser> = [
        {
            title: "주차 정보",
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            align: 'center',
            children:
                [
                    {
                        title: () => (
                            <div>
                                <TitleWrapper>주차</TitleWrapper>
                            </div>
                        ),
                        key: 'week',
                        width: 100,
                        align: 'center',
                        render: (_, record) => (
                            <div>
                                <Tag>1주차 추천</Tag>
                            </div>
                        )
                    },
                    {
                        title: () => (
                            <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                                <div>추천 현황</div>
                                <div style={{fontSize: '12px', color: '#808080'}}>추천 수 / 가능 수</div>
                            </div>
                        ),
                        key: 'Recommendations',
                        width: 250,
                        align: 'center',
                        render: (_, record) => {

                            // 각 추천 타입별로 컴포넌트를 생성합니다
                            const renderRecommendationRow = (
                                label: string,
                                count: number,
                                range: string
                            ) => (
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: '80px 1fr',
                                    gap: '16px',
                                    width: '100%'
                                }}>
                                    <div style={{
                                        textAlign: 'left',
                                        color: '#000000',  // 레이블 색상을 검정색으로 변경
                                        fontWeight: 'bold'
                                    }}>
                                        {label}
                                    </div>
                                    <div style={{
                                        textAlign: 'left'  // 값을 왼쪽 정렬로 변경
                                    }}>
                                        <b>{count}</b> / {range}
                                    </div>
                                </div>
                            );

                            return (
                                <Space direction="vertical" size={8}>
                                    {renderRecommendationRow(
                                        "미끼",
                                        record?.numOfUpperLevelToRecommendations || 0,
                                        "0~2"
                                    )}
                                    {renderRecommendationRow(
                                        "동급 괜찮은",
                                        record?.numOfSameLevelPrimaryToRecommendations || 0,
                                        "3~4"
                                    )}
                                    {renderRecommendationRow(
                                        "동급 아쉬운",
                                        record?.numOfSameLevelSecondaryToRecommendations || 0,
                                        "3~4"
                                    )}
                                </Space>
                            );
                        }
                    }
                ]
        },
        {
            title: '매력 정보',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            align: 'center',
            children: [
                {
                    title: '프로필',
                    key: 'profile',
                    width: 200,
                    align: 'center',
                    render: (_, record) => (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Image
                                src={record.userProfileImages[0]}
                                height={200}
                                width={200}
                                style={{objectFit: 'cover'}}
                                preview={{
                                    width: 'auto',  // 프리뷰 모달의 너비
                                    height: 'auto', // 프리뷰 모달의 높이
                                    mask: <div>클릭하여 크게보기</div>, // 선택적: 호버시 보여줄 텍스트
                                }}
                            />
                        </div>
                    )
                }
            ]
        },
        {
            title: '기본 정보',
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            children: [
                // {
                //     title: 'UID',
                //     key: 'id',
                //     width: 150,
                //     align: 'center',
                //     render: (_, record) => {
                //         // ID를 5글자로 자르는 로직을 함수로 분리하여 가독성 향상
                //         const truncateId = (id: string) => {
                //             if (id.length <= 5) return id;
                //             return `${id.slice(0, 5)}...`;
                //         };
                //
                //         return (
                //             <Space onClick={(e) => e.stopPropagation()}>
                //                 <Tooltip title={record.id}>
                //                     <Typography.Text>
                //                         {truncateId(record.id)}
                //                     </Typography.Text>
                //                 </Tooltip>
                //                 <Typography.Link
                //                     onClick={() => {
                //                         navigator.clipboard.writeText(record.id);
                //                         message.success('ID가 복사되었습니다');
                //                     }}
                //                 >
                //                     <CopyOutlined/>
                //                 </Typography.Link>
                //             </Space>
                //         )
                //     }
                // },
                {
                    title: '외모',
                    key: 'appearanceRating',
                    align: 'center',
                    width: 100,
                    render:
                        (_, record) => (
                            <Space direction="vertical">
                                <Tag
                                    color={getTagColor(record.appearanceRating, 'APPEARANCE_RATING')}>외모 {record.appearanceRating}</Tag>
                            </Space>
                        )
                },
                {
                    title: '이름 / 성별 / 나이 / 키',
                    key:
                        'basic info',
                    width: 200,
                    align: 'center',
                    render:
                        (_, record) => (
                            <Space direction="vertical">
                                <div>{record.name}({record.gender === '남자' ? '남' : '여'})</div>
                                <div>{record.age}세</div>
                                <div>{record.height}cm</div>
                            </Space>
                        )
                }
            ]
        },
        {
            title: '직업/학력 정보',
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            children:
                [{
                    title: '직업',
                    key: 'career',
                    width: 180,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <Tag
                                color={getTagColor(record.overallSocialRating, 'OVERALL_SOCIAL_RATING')}>능력 {record.overallSocialRating}</Tag>
                            <div>{record.careerDetail}</div>
                            <div>{record.careerSubGroup}</div>
                        </Space>
                    )
                },
                    {
                        title: '연봉',
                        key: 'career',
                        width: 150,
                        align: 'center',
                        render: (_, record) => (
                            <Space direction="vertical">
                                <div>{record.income != null ? formatKoreanMoney(record.income) : "-"}</div>
                            </Space>
                        )
                    },
                    {
                        title: () => (
                            <div>
                                <div>학력</div>
                            </div>
                        ),
                        key: 'career',
                        width: 180,
                        align: 'center',
                        render: (_, record) => (
                            <Space direction="vertical">
                                <Tag color={getTagColor(record.univRating, 'UNIV_RATING')}>학력 {record.univRating}</Tag>
                                <div>{record.univName !== "" ? record.univName : "-"}</div>
                            </Space>
                        )
                    }
                ]
        },
        {
            title: '성향/이상형 정보',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#6B7280',
                    color: 'white',
                    borderRight: '1px solid white'
                }
            }),
            align: 'center',
            children: [
                {
                    title: () => (
                        <div>
                            <div>성향 정보</div>
                        </div>
                    ),
                    key: 'preference',
                    width: 200,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical" size={12} style={{width: '100%', padding: '8px 0'}}>
                            <div style={{display: 'flex'}}>
                                <div style={{width: 60, fontWeight: 'bold'}}>종교</div>
                                <div>{record.religion ?? "-"}</div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <div style={{width: 60, fontWeight: 'bold'}}>흡연</div>
                                <div>{record.smokingStatus}</div>
                            </div>

                            <div style={{display: 'flex'}}>
                                <div style={{width: 60, fontWeight: 'bold'}}>음주</div>
                                <div>{record.drinkingStatus}</div>
                            </div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>이상형 정보</div>
                        </div>
                    ),
                    key: 'preferenceType',
                    align: 'center',
                    width: 180,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>타입</b>
                                </div>
                                <Tag
                                    color={getTagColor(record.preferenceType, 'PREFERENCE_TYPE')}>{record.preferenceType}</Tag>
                            </div>
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>나이</b>
                                </div>
                                {Number(record.age) - Number(record.partnerUnderAge)} ~ {Number(record.age) + Number(record.partnerUpperAge)}세
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>키</b>
                                </div>
                                {record.partnerHeightRange[0].includes('-')
                                    ? `${record.partnerHeightRange[0].replace('-', '')}`
                                    : `${record.partnerHeightRange[0]}`} ~ {
                                record.partnerHeightRange[1].includes('+')
                                    ? `${record.partnerHeightRange[1].replace('+', '')}cm`
                                    : `${record.partnerHeightRange[1]}cm`}
                            </div>
                        </Space>
                    )
                },
            ]
        }
    ];

    const handleRowClick = (record: PartnerUser) => {
        navigate(`/recommendations/${record.id}`);
    };

    return (
        <MatchLayout>
            <Card>
                {content && <RecommendedUserProfileCard data={content}/>}
            </Card>

            <Card style={{marginBottom: '16px'}}>
                <Typography.Title level={2}>{content?.name}님이 추천 받은 목록</Typography.Title>
                <InfoContainer>미끼 추천 유저 목록 : {receivedUpperLevelRecommendations?.length}/2 (미끼 추천은 0~2개)</InfoContainer>
                <SectionCard>
                    <Table
                        columns={columns}
                        dataSource={receivedUpperLevelRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{
                            x: calculateColumnsTotalWidth(columns),
                            scrollToFirstRowOnChange: true
                        }}
                        pagination={false}
                        style={{
                            '--ant-table-border-color': '#f0f0f0'  // 테두리 색상 변경
                        } as React.CSSProperties}
                    />
                </SectionCard>

                <InfoContainer>동급 괜찮은 추천 유저 목록 : {receivedSameLevelPrimaryRecommendations?.length}/4 (동급 괜찮은 추천은
                    3~4개)</InfoContainer>
                <SectionCard>
                    <Table
                        columns={columns}
                        dataSource={receivedSameLevelPrimaryRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{
                            x: calculateColumnsTotalWidth(columns),
                            scrollToFirstRowOnChange: true
                        }}
                        pagination={false}
                    />
                </SectionCard>

                <InfoContainer>동급 아쉬운 추천 유저 목록 : {receivedSameLevelSecondaryRecommendations?.length}/4 (동급 아쉬운 추천은
                    3~4개)</InfoContainer>
                <SectionCard>
                    <Table
                        columns={columns}
                        dataSource={receivedSameLevelSecondaryRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{
                            x: calculateColumnsTotalWidth(columns),
                            scrollToFirstRowOnChange: true
                        }}
                        pagination={false}
                    />
                </SectionCard>
            </Card>

            <Card>
                <Typography.Title level={2}>{content?.name}님을 추천 받은 목록</Typography.Title>
                <InfoContainer>미끼 추천 프로필로 사용 : {sentUpperLevelRecommendations?.length}</InfoContainer>
                <SectionCard>
                    <Table
                        columns={columns}
                        dataSource={sentUpperLevelRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{
                            x: calculateColumnsTotalWidth(columns),
                            scrollToFirstRowOnChange: true
                        }}
                        pagination={false}
                        style={{
                            '--ant-table-border-color': '#f0f0f0'  // 테두리 색상 변경
                        } as React.CSSProperties}
                    />
                </SectionCard>

                <InfoContainer>동급 괜찮은 추천 프로필로 사용 : {sentSameLevelPrimaryRecommendations?.length}</InfoContainer>
                <SectionCard>
                    <Table
                        columns={columns}
                        dataSource={sentSameLevelPrimaryRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{
                            x: calculateColumnsTotalWidth(columns),
                            scrollToFirstRowOnChange: true
                        }}
                        pagination={false}
                    />
                </SectionCard>

                <InfoContainer>동급 아쉬운 추천 프로필로 사용 : {sentSameLevelSecondaryRecommendations?.length}</InfoContainer>
                <SectionCard>
                    <Table
                        columns={columns}
                        dataSource={sentSameLevelSecondaryRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{
                            x: calculateColumnsTotalWidth(columns),
                            scrollToFirstRowOnChange: true
                        }}
                        pagination={false}
                    />
                </SectionCard>
            </Card>

        </MatchLayout>
    );
};

const SectionCard = styled(Card)`
    margin-bottom: 24px;

    .ant-card-head-title {
        font-size: 20px; // 타이틀 폰트 크기를 20px로 설정
    }

    .ant-card-body {
        padding: 0; // Card 내부 패딩 제거
    }

    .ant-table-wrapper {
        margin: 0; // 테이블 래퍼의 마진 제거
    }
`;

const InfoContainer = styled.div`
    background-color: rgb(108, 117, 125);
    color: white;
    padding: 12px 16px;
    margin: 16px 0;
    font-weight: 600; // 텍스트를 진하게 만드는 속성 추가
    letter-spacing: 0.3px; // 글자 간격을 약간 넓혀 가독성 향상
`;

export default RecommendationDetail;