import React, {useEffect, useState} from 'react';
import {Button, Input, Space} from 'antd';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';


export interface FilterValues {
    name: string;
    gender: string;
    overallSocialRating: string;
    appearanceRating: string;
    univRating: string;
    preferenceType: string;
    userId: string;
}

interface RecommendationGlobalTableFilterProps {
    filter: FilterValues;
    onFilterChange: (values: Partial<FilterValues>) => void;
    onReset: () => void;
}

const RecommendationGlobalTableFilter: React.FC<RecommendationGlobalTableFilterProps> = ({
                                                                                             filter,
                                                                                             onFilterChange,
                                                                                             onReset
                                                                                         }) => {

    // 로컬 상태 추가
    const [localUserId, setLocalUserId] = useState(filter.userId);
    const [localName, setLocalName] = useState(filter.name);

    // 엔터키 입력 시 필터 적용을 위한 함수
    const handleUserIdEnter = () => {
        onFilterChange({userId: localUserId});
    };

    const handleNameEnter = () => {
        onFilterChange({name: localName});
    };

    // filter prop이 외부에서 변경될 때 로컬 상태 동기화
    useEffect(() => {
        setLocalUserId(filter.userId);
        setLocalName(filter.name);
    }, [filter.userId, filter.name]);

    return (
        <Space style={{
            padding: 8,
        }}>
            <Space size="middle" style={{display: 'flex', alignItems: 'center'}}>
                <div style={{width: 208}}>
                    <Input
                        placeholder="UID를 입력하세요."
                        value={localUserId}
                        onChange={(e) => setLocalUserId(e.target.value)}  // 로컬 상태만 업데이트
                        onPressEnter={handleUserIdEnter}  // 엔터키 입력 시 필터 적용
                        prefix={<SearchOutlined style={{color: '#999'}}/>}
                        allowClear
                    />
                </div>
                <div style={{width: 208}}>
                    <Input
                        placeholder="회원 이름을 입력하세요."
                        value={localName}
                        onChange={(e) => setLocalName(e.target.value)}  // 로컬 상태만 업데이트
                        onPressEnter={handleNameEnter}  // 엔터키 입력 시 필터 적용
                        prefix={<SearchOutlined style={{color: '#999'}}/>}
                        allowClear
                    />
                </div>
                <Button
                    icon={<ReloadOutlined/>}
                    onClick={() => {
                        // 리셋 시 로컬 상태도 초기화
                        setLocalUserId('');
                        setLocalName('');
                        onReset();
                    }}
                >
                    초기화
                </Button>

            </Space>
        </Space>
    );
};

export default RecommendationGlobalTableFilter;